.wrapper {
    min-height: 100vh;
    display: flex;
}

.card {
    position: relative;
    margin: auto;
    width: 300px;
    padding: 45px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
    display: flex;
    align-items: center;
    flex-direction: column;
}

.imageLogoWrapper {
    display: flex;
    margin-bottom: 15px;
}
