$primary-blue: #05cacc;
$primary-purple: #8032ed;
$error: #dc3545;


// exported variables could be accessed in JavaScript environment.
// No need to export variable if it's meant to be used only in CSS environment.
:export {
    primaryBlue: $primary-blue;
    primaryPurple: $primary-purple;
}
