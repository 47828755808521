.wrapper {
    display: flex;
    height: 100vh;
    align-items: stretch;
}

.sidebarWrapper {
    width: 300px;
    flex: 0 0 auto;
}

.contentWrapper {
    flex: 1;
    overflow: auto;
    position: relative;
}
