@import "styles/theme";

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loginWrapper,
.passwordWrapper {
    margin-bottom: 15px;
    text-align: center;
}

.loginInput,
.passwordInput {
    border: 1px solid #ced4da;
    border-radius: 4px;
    outline: none;
    padding: 6px 12px;
    width: 100%;
    height: 40px;
}

.loginButton {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 14px;
    padding: 12px;
}

.validationError {
    color: $error;
    margin-top: 5px;
}
