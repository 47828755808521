.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.avatar {
    width: 85px;
    height: 85px;
    border-radius: 50%;
    object-fit: contain;
    margin-bottom: 10px;
}

.name {
    font-weight: bold;
    margin-bottom: 5px;
}
