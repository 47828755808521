@import 'styles/theme';

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.selectedYear {
    font-size: 30px;
    margin: 0 40px;
}

.prevYearButton,
.nextYearButton {
    font-size: 30px;
    background: none;
    border: none;
    padding: 5px 15px;
    cursor: pointer;
    outline: none;
}
