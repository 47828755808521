.wrapper {
    background: #c7d0d8;
    padding: 50px;
}

.title {
    font-size: 2.5em;
    margin: 0 0 50px 0;
    text-align: center;
}

.lastUpdate {
    font-size: 1.25em;
    font-weight: bold;
    margin-top: 50px;
    text-align: center;
}
