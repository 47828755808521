@import "styles/theme";

.wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
}

.innerWrapper {
    position: fixed;
    display: flex;
    background: rgba(255, 255, 255, 0.7);
    z-index: 1;
}

.spinner {
    margin: auto;
    color: $primary-blue;
    font-size: 80px;
    animation: rotateSpinner 0.8s linear infinite;
}

.visuallyHidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    // https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
    white-space: nowrap;
    word-wrap: normal;
}

@keyframes rotateSpinner {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}
