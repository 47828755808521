.wrapper {
    height: 100%;
    padding: 25px 0 0 0;
    border-right: 1px solid #f1f1f1;
}

.menuWrapper {
    margin-top: 40px;
}

.menuItemWrapper {
    margin: 12px 0;
}
