@import 'styles/theme';

.wrapper {
    padding: 6px 10px 6px 45px;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    overflow: hidden;
    border-left: 5px solid transparent;
}

.wrapperActive {
    border-left: 5px solid $primary-purple;
}

.icon {
    margin-right: 15px;
    font-size: 20px;
    color: $primary-blue;
}

.name {
    font-weight: 600;
    color: #7a6e8a;
    &:hover {
        color: $primary-purple;
    }
}

.wrapperActive .name {
    color: $primary-purple;
}
